import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

import "./gridCard.css"

export default props => {
  const { item: news, defaultImage } = props
  const { header, subheader, images, slug } = news

  const firstImage = images && images.length && images[0]

  return (
    <Link to={"/news/" + slug}>
      <div className="grid-card">
        <div className="grid-card-image">
          {firstImage ? (
            <Img fluid={firstImage.image.childImageSharp.fluid} />
          ) : (
            <Img fluid={defaultImage.childImageSharp.fluid} />
          )}
        </div>
        <div className="grid-card-hover">
          <div className="grid-card-text">
            <h1>{header}</h1>
            <h2>{subheader}</h2>
          </div>
        </div>
      </div>
    </Link>
  )
}
