import React from "react"
import { graphql, Link } from "gatsby"

import Grid from "../components/grid"
import GridItem from "../components/grid/GridItem"

export default ({ data }) => {
  const { younion, defaultImage } = data
  const { pages } = younion
  const home = pages.find(page => !page.path)
  const { grid } = home
  const { gridSize, items } = grid

  return (
    <div id="home">
      <h1>Younion Burgenland</h1>

      <Grid
        gridSize={gridSize}
        items={items}
        render={item => <GridItem {...item} defaultImage={defaultImage} />}
      />

      <h3>
        <Link to="/news">Lesen Sie hier alle News</Link>
      </h3>
    </div>
  )
}

export const query = graphql`
  query {
    younion {
      pages {
        _id
        title
        path
        grid {
          gridSize
          items {
            width
            height
            itemId
            schema
            item {
              _id
              ... on Younion_NewsItem {
                ...newsItemFields
              }
              ... on Younion_Promotion {
                ...promotionFields
              }
              ... on Younion_PromotionCategory {
                ... promotionCategoryFields
              }
              ... on Younion_Page {
                ...pageFields
              }
            }
          }
        }
      }
    }

    defaultImage: file(relativePath: { eq: "younion_logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 2400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
