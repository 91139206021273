import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

import "./gridCard.css"

const getPathFromCategories = categories => {
  if (!categories?.length) return ""

  const getSlug = (parent) => {
    if (parent.parent?.length === 0) return parent.slug + "/"
    return getSlug(parent.parent[0]) + parent.slug + "/"
  }

  const firstParent = categories[0]

  return getSlug(firstParent)
}

export default props => {
  const { item: promotion, defaultImage } = props
  const { header, subheader, images, slug, categories } = promotion

  const path = getPathFromCategories(categories)
  const firstImage = images && images.length && images[0]

  return (
    <Link to={`/service/${path}${slug}`}>
      <div className="grid-card">
        <div className="grid-card-image">
          {firstImage ? (
            <Img fluid={firstImage.image.childImageSharp.fluid} />
          ) : (
            <Img fluid={defaultImage.childImageSharp.fluid} />
          )}
        </div>
        <div className="grid-card-hover">
          <div className="grid-card-text">
            <h1>{header}</h1>
            <h2>{subheader}</h2>
          </div>
        </div>
      </div>
    </Link>
  )
}
