import React from "react"

import GridCardNews from "../cards/GridCardNews"
import GridCardPromotion from "../cards/GridCardPromotion"
import GridCardPromotionCategory from "../cards/GridCardPromotionCategory"
import GridCardPage from "../cards/GridCardPage"

export default ({ item, schema, defaultImage }) => {
  if (!item) return <div>Not existing or not visible</div>

  switch (schema) {
    case "news":
      return <GridCardNews item={item} defaultImage={defaultImage} />
    case "promotion":
      return <GridCardPromotion item={item} defaultImage={defaultImage} />
    case "category_promotion":
      return <GridCardPromotionCategory item={item} defaultImage={defaultImage} />
    case "page":
      return <GridCardPage item={item} defaultImage={defaultImage} />
    default:
      return <div>Not defined Grid</div>
  }
}
