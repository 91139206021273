import React from "react"

import "./Grid.css"

export default ({ items = [], gridSize, render = () => {} }) => {
  return (
    <div className="grid">
      <div className="grid-container">
        {items.map((item, index) => {
          return (
            <div
              className="grid-item"
              style={{ width: `calc((100% / ${gridSize}) - 50px)` }}
              key={index}
            >
              {render(item)}
            </div>
          )
        })}
      </div>
    </div>
  )
}
