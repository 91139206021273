import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

import "./gridCard.css"

export default props => {
  const { item: promotionCategory, defaultImage } = props
  const { slug, title, image } = promotionCategory

  return (
    <Link to={`/service/${slug}`}>
      <div className="grid-card">
        <div className="grid-card-image">
        {image ? (
            <Img fluid={image.image.childImageSharp.fluid} />
          ) : (
            <Img fluid={defaultImage.childImageSharp.fluid} />
          )}
        </div>
        <div className="grid-card-hover">
          <div className="grid-card-text">
            <h1>{title}</h1>
          </div>
        </div>
      </div>
    </Link>
  )
}
