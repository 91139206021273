import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

import "./gridCard.css"

export default props => {
  const { item: page, defaultImage } = props
  const { title, path, parent, image } = page

  const slug = parent.path ? `${parent.path}/${path}` : `/${path}`
  const firstImage = image

  return (
    <Link to={slug}>
      <div className="grid-card">
        <div className="grid-card-image">
          {firstImage ? (
            <Img fluid={firstImage.image.childImageSharp.fluid} />
          ) : (
            <Img fluid={defaultImage.childImageSharp.fluid} />
          )}
        </div>
        <div className="grid-card-hover">
          <div className="grid-card-text">
            <h1>{title}</h1>
          </div>
        </div>
      </div>
    </Link>
  )
}
